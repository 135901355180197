import React, { useState, useEffect, useContext } from 'react'
import { setSelectValue, loadScript, emit, validation, getFormData, showNotify, formatDate } from '../context/socket'
import Switch from './Switch';
import AHrefJavascript from './AHrefJavascript';
import $ from 'jquery';
import { ConvertIsoDate } from '../context/common_function'
import { Link, useHistory } from "react-router-dom";
import Pagination from './Pagination';
import TomSelect from 'tom-select';

const AppsSetting = ({ setPath, location }) => {
    const [versions, setVersions] = useState([])
    const [banks, setBanks] = useState([])
    const [version, setVersion] = useState({})
    const [bank, setBank] = useState([])
    const [setting, setSetting] = useState({})
    const [docsCounts, setDocsCounts] = useState(0)
    const [docsCounts2, setDocsCounts2] = useState(0)
    const history = useHistory();
    const [pageSize, setPageSize] = useState(10)
    const [pageSize2, setPageSize2] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [currentPage2, setCurrentPage2] = useState(1)
    const [refresh, setRefresh] = useState(1)
    const [typeDics, setTypeDisc] = useState('fixed')

    useEffect(() => {
        const getGeneral = async () => {
            var reqData = {
                'page': currentPage,
                'size': pageSize,
            }
            emit('app_versions', reqData, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setVersions(response.data.docs)
                setDocsCounts(response.data.counts)
            });
        }
        getGeneral()
    }, [currentPage, pageSize, refresh])
    useEffect(() => {
        const getBanks = async () => {
            var reqData = {
                'text':'',
                'page': currentPage2,
                'size': pageSize2,
            }
            emit('get_app_banks', reqData, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setBanks(response.data.docs)
                setDocsCounts2(response.data.counts)
            });
        }
        getBanks()
    }, [refresh,currentPage2, pageSize2])
    useEffect(() => {
        loadScript().then(() => {
            getSetting();
        })
        setPath({title:'Settings',path:'/settings'});
        // setPath('/settings')
        $(() => {
            $('#addVersion').on('click', () => {
                addVersion()
            })
            $('#addBank').on('click', () => {
                addBank()
            })

            $('#editVersion').on('click', () => {
                editVersion()
            })
            $('#editBank').on('click', () => {
                editBank()
            })
            
        })
    }, [])

    useEffect(() => {
        if (setting._id) getSetting()
    }, [refresh])
    const addBank = () => {
        var required = $('#add-bank input,#add-bank textarea,#add-bank select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });
        var form2 = getFormData($('#add-bank'))
        if (!validation(form2, required)) return;
        // console.log(form2);
        emit('create_bank', form2, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            setRefresh((new Date).getTime())
            $('#closeModalAddBank').trigger('click');
        });
    }
    const getSetting = () => {
        emit('get_app_setting', {}, (response) => {
            if (response.response < 200 || response.response >= 300) return showNotify(response)
            setSetting(response.data)
            createPromoSelect(response.data)
            if (response.data.maintenance_date) {
                var date = new Date(response.data.maintenance_date)
                var min = date.getMinutes();
                min = `${min}`.length == 1 ? '00' : min;
                var time = date.getHours() + ':' + min
                console.log(time);
                setSelectValue('time', time)
            }
        });
    }
    //add_version
    // const save = (data) => {
    //     setRefresh((new Date).getTime())
    // }
    const createPromoSelect = (setting) => {
        var select = document.getElementById('promo')
        if (!select) return;
        var control = select.tomselect;
        if (control) control.destroy();
        new TomSelect('#promo', {
            valueField: '_id',
            labelField: 'name',
            searchField: ['name'],
            plugins: {
                dropdown_input: {}
            },
            onInitialize: function (params) {
                if (!setting.promo) return;
                var select = document.getElementById('promo')
                var control = select.tomselect;
                control.addOption({ name: setting.promo.code, _id: setting.promo._id });
                control.addItem(setting.promo._id);
            },
            // fetch remote data
            load: function (query, callback) {
                var reqData = {
                    'text': query,
                    'page': 1,
                    'size': 10,
                    'isAvailable': true
                }
                emit('get_list_promo', reqData, (response) => {
                    if (response.response < 200 || response.response >= 300) return showNotify(response)
                    var result = response.data.docs.map((e) => {
                        return { name: e.code, _id: e._id }
                    })
                    callback(result);
                });
            },
        });
    }
    const hapus = (id) => {
        var res = confirm('Anda Yakin ingin menghapus Data ini?')
        if (res == 0) return;
        emit('remove_version', { idVersion: id }, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            setRefresh((new Date).getTime())
        });
    }
    const hapusBank = (id) => {
        var res = confirm('Anda Yakin ingin menghapus Data ini?')
        if (res == 0) return;
        emit('delete_bank', { id: id }, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            setRefresh((new Date).getTime())
        });
    }
    
    const addVersion = (data) => {
        var required = $('#add input,#add textarea,#add select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });
        var form2 = getFormData($('#add'))
        if (!validation(form2, required)) return;
        form2.urgent = form2.urgent || false
        // console.log(form2);
        emit('add_version', form2, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            setRefresh((new Date).getTime())
            $('#closeModalAdd').trigger('click');
        });
    }

    const toggle = (data) => {
        data.status = data.status == 'active' ? 'inactive' : 'active'
        var reqData = {
            id: data._id,
            status: data.status,
            type: data.type,
            version: data.version,
            urgent: data.urgent
        }
        console.log(reqData);
        emit('edit_version', reqData, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            setRefresh((new Date).getTime())
        });
    }

    const editVersion = (data) => {
        var required = $('#edit input,#edit textarea,#edit select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });
        var form2 = getFormData($('#edit'))
        if (!validation(form2, required)) return;

        form2.urgent = form2.urgent || false
        console.log(form2);
        emit('edit_version', form2, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            setRefresh((new Date).getTime())
            $('#closeModalEdit').trigger('click');
        });
    }
    const editBank = (data) => {
        var required = $('#edit-bank input,#edit-bank textarea,#edit-bank select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });
        var form2 = getFormData($('#edit-bank'))
        if (!validation(form2, required)) return;
        console.log(form2);
        emit('edit_bank', form2, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            setRefresh((new Date).getTime())
            $('#closeModalEditBank').trigger('click');
        });
    }
    const openEditModal = (data) => {
        setVersion(data)
        setSelectValue('select-type', data.type)
    }
    const openEditModalBank = (data) => {
        setBank(data)
    }
    const handleType = (e) => {
        var type = e.target.value
        setTypeDisc(type);
        if (type == 'fixed') type = 'Rp.';
        else type = '%'
        $('#input-group-value').html(type)
    }
    const save = () => {
        var required = $('#input input,#input textarea,#input select').filter('[required]');
        console.log(required)
        required = $.map(required, function (value, index) {
            return value.name;
        });
        var form2 = getFormData($('#appSetting'))
        if (!validation(form2, required)) return;
        form2.globalDisc = setting.globalDisc
        if (!form2.promo) form2.promo = null;
        if (!setting.maintenance_date) form2.maintenance_date = null;
        else {
            form2.maintenance_date += ` ${form2.time}`;
            form2.maintenance_date =new Date(form2.maintenance_date).toISOString()
        }
        delete form2.time;
        console.log(form2);
        // return;
        emit('edit_app_setting', form2, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            setRefresh((new Date).getTime())
        });
    }
    return (
        <>
            <div className="intro-y">
                {/* <!-- END: Top Bar --> */}
                <h2 className="intro-y text-lg font-medium mt-10">
                    App Setting
                </h2>
                <div className="w-3/4 mt-5">
                    {/* <!-- BEGIN: Input --> */}
                    <form id="appSetting">
                        <input name='id' type='hidden' defaultValue={setting._id}></input>

                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                                <h2 className="font-medium text-base mr-auto">
                                    Display Information
                                </h2>
                            </div>
                            <div className="flex flex-col xl:flex-row flex-col">
                                <div id="input" className="flex-1 mt-6 xl:mt-0 p-5">
                                    <div className="preview">
                                        <div className='flex items-center mb-3'>
                                            <div className="font-bold ">Global Discount</div>
                                            <Switch
                                                isOn={setting.globalDisc || false}
                                                onColor="#1be708"
                                                handleToggle={() => { setSetting({ ...setting, globalDisc: !setting.globalDisc }) }}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <div onChange={(e) => handleType(e)} className="dropdown mr-2 relative text-gray-700 dark:text-gray-300">
                                                <select id="promo" name="promo" className="tom-select sm:mr-2" placeholder="Promo">
                                                    {/* <option value={'fixed'} label="Fixed">Fixed</option>
                                                        <option value={'percentage'} label="Persentase">Persentase</option> */}
                                                </select>
                                            </div>
                                            {/* <div className="grid grid-cols-3 gap-2">
                                                <label htmlFor="regular-form-4" className="form-label">Type <span className="text-red-500"> *</span></label>
                                                <label htmlFor="regular-form-3" className="form-label">Value <span className="text-red-500"> *</span></label>
                                                {typeDics != 'fixed' && <label htmlFor="regular-form-4" className="form-label">Max Value <span className="text-red-500"> *</span></label>}
                                            </div>
                                            <div className="grid grid-cols-12 gap-2">
                                               
                                                <div className="input-group col-span-4">
                                                    <div id="input-group-value" className="input-group-text">Rp.</div>
                                                    <input type="number" defaultValue={setting.disc_value} className="form-control" name="disc_value" required placeholder="Nilai Discount" />
                                                </div>
                                                {typeDics != 'fixed' && <div className="input-group col-span-4">
                                                    <div className="input-group-text">Rp.</div>
                                                    <input type="number" defaultValue={setting.disc_max} className="form-control" name="disc_max" required placeholder="Max Discount" />
                                                </div>}
                                            </div> */}
                                        </div>
                                        <div className="mt-3">
                                            <div className='flex items-center mb-3'>
                                                <div className="font-bold ">Maintenance</div>
                                                <Switch
                                                    isOn={setting.maintenance_date || false}
                                                    onColor="#1be708"
                                                    data={setting}
                                                    handleToggle={() => { setSetting({ ...setting, maintenance_date: setting.maintenance_date ? null : formatDate(new Date()) }) }}
                                                />
                                            </div>
                                            <div className='flex'>
                                                {setting.maintenance_date &&
                                                    <input type="date" name="maintenance_date" defaultValue={setting.maintenance_date && ConvertIsoDate(setting.maintenance_date)} className="datepicker form-control col-span-4" data-single-mode="true" data-format="YYYY-MM-DD" placeholder="Birth Date" />
                                                }
                                                <div className={"dropdown mr-2 w-48 relative text-gray-700 dark:text-gray-300 " + (setting.maintenance_date ? 'block' : 'hidden')}>
                                                    <select id='time' name="time" className="tom-select sm:mr-2" placeholder="Promo">
                                                        {(() => {
                                                            const options = []
                                                            for (let index = 0; index < 24; index++) {
                                                                options.push(<option key={`${index}:1`} >{`${index}:00`}</option>)
                                                                options.push(<option key={`${index}:2`}>{`${index}:30`}</option>)
                                                            }
                                                            return options;
                                                        })()}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <div className="grid grid-cols-2 gap-2">
                                                <label htmlFor="regular-form-3" className="form-label">Pembagian <span className="text-red-500"> *</span></label>
                                            </div>
                                            <div className="grid grid-cols-8 gap-2">
                                                <div className="input-group col-span-2">
                                                    <div id="input-group-value2" className="input-group-text">%</div>
                                                    <input type="number" defaultValue={setting.pembagian} className="form-control" name="pembagian" required placeholder="Persentase Bagi Lawyer" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className='flex justify-end mt-5'>
                        <div className="text-right">
                            <button type="button" onClick={save} className="btn btn-dark shadow-md">Save</button>
                        </div>
                    </div>
                    {/* <!-- END: Input --> */}
                </div>
            </div>
            <div className="intro-y">
                {/* <!-- END: Top Bar --> */}
                <div className='flex w-3/4 justify-between mt-10'>
                    <h2 className="intro-y text-lg font-medium ">
                        Laporhukum Bank
                    </h2>
                    <AHrefJavascript data-toggle="modal" href="#!" data-target="#add-bank-modal" className="btn shadow-md bg-white">
                        Tambah Bank
                    </AHrefJavascript>
                </div>
                <form id="version">
                    <div className="w-3/4 mt-5">
                        {/* <!-- BEGIN: Input --> */}
                        <div className="intro-y box">
                            <table className="table table-report sm:mt-2">
                                <thead>
                                    <tr>
                                        <th className="whitespace-nowrap">Bank</th>
                                        <th className="text-center whitespace-nowrap">Name</th>
                                        <th className="text-center whitespace-nowrap">Number</th>
                                        <th className="text-center whitespace-nowrap">Branch</th>
                                        <th className="text-center whitespace-nowrap">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {banks.map((d, i) => {
                                        i *= 1
                                        return (
                                            <tr key={i} className="intro-x">
                                                <td className="w-1">
                                                    <div className="flex">
                                                        <div className="text-gray-900 flex items-center text-xs whitespace-nowrap mx-5 my-0.5" key={d._id}>
                                                            <div className="font-bold">{d.bank_name}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="w-1">
                                                    <div className="flex justify-center">
                                                        <div className="text-gray-900 text-xs whitespace-nowrap mx-5 my-0.5" key={d._id}>
                                                            <div className="text-center">{d.account_name}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="w-40">
                                                    <div className="flex items-center justify-center">
                                                       {d.account_number}
                                                    </div>
                                                </td>
                                                <td className="w-40">
                                                    <div className="flex items-center justify-center">
                                                       {d.bank_branch}
                                                    </div>
                                                </td>
                                                <td className="table-report__action w-56">
                                                    <div className="flex justify-center items-center">
                                                        <AHrefJavascript data-toggle="modal" href="#!" data-target="#edit-bank-modal" onClick={() => openEditModalBank(d)} className="flex items-center mr-3" > <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-square w-4 h-4 mr-1"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>  Edit </AHrefJavascript>
                                                        <AHrefJavascript onClick={() => hapusBank(d._id)} href="#!" className="flex items-center mr-3" > <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-square w-4 h-4 mr-1"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>  Hapus </AHrefJavascript>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={docsCounts}
                                    pageSize={pageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                                <div className='w-full'></div>
                                <select defaultValue={10} onChange={(e) => setPageSize(parseInt(e.target.value))} style={{ width: '100px' }} className="w-20 form-select box mt-3 sm:mt-0">
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={35}>35</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                        </div>
                        {/* <!-- END: Input --> */}
                    </div>
                </form>
            </div>
            <div className="intro-y">
                {/* <!-- END: Top Bar --> */}
                <div className='flex w-3/4 justify-between mt-10'>
                    <h2 className="intro-y text-lg font-medium ">
                        App Setting
                    </h2>
                    <AHrefJavascript data-toggle="modal" href="#!" data-target="#add-modal" className="btn shadow-md bg-white">
                        Tambah Version
                    </AHrefJavascript>
                </div>
                <form id="version">
                    <div className="w-3/4 mt-5">
                        {/* <!-- BEGIN: Input --> */}
                        <div className="intro-y box">
                            <table className="table table-report sm:mt-2">
                                <thead>
                                    <tr>
                                        <th className="whitespace-nowrap">Version</th>
                                        <th className="text-center whitespace-nowrap">Type</th>
                                        <th className="text-center whitespace-nowrap">Status</th>
                                        <th className="text-center whitespace-nowrap">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {versions.map((d, i) => {
                                        i *= 1
                                        return (
                                            <tr key={i} className="intro-x">
                                                <td className="w-1">
                                                    <div className="flex">
                                                        <div className="text-gray-900 flex items-center text-xs whitespace-nowrap mx-5 my-0.5" key={d._id}>
                                                            {d.urgent ? <span className="material-icons mr-2 text-red-500">new_releases</span> : <></>}
                                                            <div className="font-bold">{d.version}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="w-1">
                                                    <div className="flex justify-center">
                                                        <div className="text-gray-900 text-xs whitespace-nowrap mx-5 my-0.5" key={d._id}>
                                                            <div className="font-bold uppercase text-center">{d.type}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="w-40">
                                                    <div className="flex items-center justify-center">
                                                        <Switch
                                                            isOn={d.status == 'active'}
                                                            onColor="#1be708"
                                                            data={d}
                                                            handleToggle={() => toggle(d)}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="table-report__action w-56">
                                                    <div className="flex justify-center items-center">
                                                        <AHrefJavascript data-toggle="modal" href="#!" data-target="#edit-modal" onClick={() => openEditModal(d)} className="flex items-center mr-3" > <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-square w-4 h-4 mr-1"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>  Edit </AHrefJavascript>
                                                        <AHrefJavascript onClick={() => hapus(d._id)} href="#!" className="flex items-center mr-3" > <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-square w-4 h-4 mr-1"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>  Hapus </AHrefJavascript>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={docsCounts}
                                    pageSize={pageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                                <div className='w-full'></div>
                                <select defaultValue={10} onChange={(e) => setPageSize(parseInt(e.target.value))} style={{ width: '100px' }} className="w-20 form-select box mt-3 sm:mt-0">
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={35}>35</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                        </div>
                        {/* <!-- END: Input --> */}
                    </div>
                </form>
            </div>
            <div id="add-modal" className="modal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        {/* <!-- BEGIN: Modal Header --> */}
                        <div className="modal-header">
                            <h2 className="font-medium text-base mr-auto">Tambah App Version</h2>
                        </div>
                        {/* <!-- END: Modal Header --> */}
                        {/* <!-- BEGIN: Modal Body --> */}
                        <div className={`px-5 pt-5`}>
                            <form id='add'>
                                <label htmlFor="modal-form-1" className="form-label">Version</label>
                                <input type="text" id='modal-form-1' className='form-control mb-2' name='version' required />
                                <label htmlFor="regular-form-5" className="form-label">Type<span className="text-red-500"> *</span></label>
                                {<select data-placeholder="Type Version" name="type" id="type" required className="tom-select w-full">
                                    {['Android', 'IOs'].map(e =>
                                        <option key={e} value={e.toLowerCase()}>{e}</option>
                                    )}
                                </select>}
                                <br></br>
                                <div className="form-check ml-5 mr-2 font-bold"> <input id="checkbox-switch-4" className="form-check-input" type="checkbox" name="urgent" value="true" /> <label className="form-check-label" htmlFor="checkbox-switch-4">Force Update</label> </div>
                                <br></br>
                            </form>
                        </div>
                        {/* <!-- END: Modal Body --> */}
                        {/* <!-- BEGIN: Modal Footer --> */}
                        <div className="modal-footer">
                            <button id="closeModalAdd" type="button" data-dismiss="modal" className="btn btn-outline-secondary w-20 mr-1">Cancel</button>
                            <button id="addVersion" type="button" className="btn btn-primary w-20">Save</button>
                        </div>
                        {/* <!-- END: Modal Footer --> */}
                    </div>
                </div>
            </div>
            <div id="edit-modal" className="modal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        {/* <!-- BEGIN: Modal Header --> */}
                        <div className="modal-header">
                            <h2 className="font-medium text-base mr-auto">Edit Version</h2>
                        </div>
                        {/* <!-- END: Modal Header --> */}
                        {/* <!-- BEGIN: Modal Body --> */}
                        <div className={`px-5 pt-5`}>
                            <form id='edit'>
                                <input type="hidden" id='' className='form-control mb-2' name='id' defaultValue={version._id} required />
                                <label htmlFor="modal-form-1-2" className="form-label">Version</label>
                                <input type="text" id='modal-form-1-2' className='form-control mb-2' name='version' required defaultValue={version.version} />
                                <label htmlFor="regular-form-5" className="form-label">Type<span className="text-red-500"> *</span></label>
                                {<select data-placeholder="Type Version" name="type" id="select-type" required className="tom-select w-full">
                                    {['Android', 'IOs'].map(e =>
                                        <option key={e} value={e.toLowerCase()}>{e}</option>
                                    )}
                                </select>}
                                <br></br>
                                <div className="form-check ml-5 mr-2 font-bold"> <input id="checkbox-switch-4" className="form-check-input" type="checkbox" name="urgent" defaultChecked={version.urgent} value="true" /> <label className="form-check-label" htmlFor="checkbox-switch-4">Force Update</label> </div>
                                <br></br>
                            </form>
                        </div>
                        {/* <!-- END: Modal Body --> */}
                        {/* <!-- BEGIN: Modal Footer --> */}
                        <div className="modal-footer">
                            <button id="closeModalEdit" type="button" data-dismiss="modal" className="btn btn-outline-secondary w-20 mr-1">Cancel</button>
                            <button id="editVersion" type="button" className="btn btn-primary w-20">Edit</button>
                        </div>
                        {/* <!-- END: Modal Footer --> */}
                    </div>
                </div>
            </div>
            <div id="add-bank-modal" className="modal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        {/* <!-- BEGIN: Modal Header --> */}
                        <div className="modal-header">
                            <h2 className="font-medium text-base mr-auto">Tambah App Bank</h2>
                        </div>
                        {/* <!-- END: Modal Header --> */}
                        {/* <!-- BEGIN: Modal Body --> */}
                        <div className={`px-5 pt-5`}>
                            <form id='add-bank'>
                                {/* <label htmlFor="modal-form-1" className="form-label">account_name bank_branch account_number bank_name</label> */}
                                <label htmlFor="modal-form-1" className="form-label">Atas Nama</label>
                                <input type="text" id='modal-form-1' className='form-control mb-2' name='account_name' required />
                                <label htmlFor="modal-form-1" className="form-label">Nama Bank</label>
                                <input type="text" id='modal-form-1' className='form-control mb-2' name='bank_name' required />
                                <label htmlFor="modal-form-1" className="form-label">Nomor Rekening</label>
                                <input type="text" id='modal-form-1' className='form-control mb-2' name='account_number' required />
                                <label htmlFor="modal-form-1" className="form-label">Cabang Bank</label>
                                <input type="text" id='modal-form-1' className='form-control mb-2' name='bank_branch' required />
                            </form>
                        </div>
                        {/* <!-- END: Modal Body --> */}
                        {/* <!-- BEGIN: Modal Footer --> */}
                        <div className="modal-footer">
                            <button id="closeModalAddBank" type="button" data-dismiss="modal" className="btn btn-outline-secondary w-20 mr-1">Cancel</button>
                            <button id="addBank" type="button" className="btn btn-primary w-20">Save</button>
                        </div>
                        {/* <!-- END: Modal Footer --> */}
                    </div>
                </div>
            </div>
            <div id="edit-bank-modal" className="modal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        {/* <!-- BEGIN: Modal Header --> */}
                        <div className="modal-header">
                            <h2 className="font-medium text-base mr-auto">Tambah App Bank</h2>
                        </div>
                        {/* <!-- END: Modal Header --> */}
                        {/* <!-- BEGIN: Modal Body --> */}
                        <div className={`px-5 pt-5`}>
                            <form id='edit-bank'>
                                <input type="hidden" id='modal-form-1' className='form-control mb-2' name='id' defaultValue={bank._id} required />
                                {/* <label htmlFor="modal-form-1" className="form-label">account_name bank_branch account_number bank_name</label> */}
                                <label htmlFor="modal-form-1" className="form-label">Atas Nama</label>
                                <input type="text" id='modal-form-1' className='form-control mb-2' name='account_name' defaultValue={bank.account_name} required />
                                <label htmlFor="modal-form-1" className="form-label">Nama Bank</label>
                                <input type="text" id='modal-form-1' className='form-control mb-2' name='bank_name' defaultValue={bank.bank_name} required />
                                <label htmlFor="modal-form-1" className="form-label">Nomor Rekening</label>
                                <input type="text" id='modal-form-1' className='form-control mb-2' name='account_number' defaultValue={bank.account_number} required />
                                <label htmlFor="modal-form-1" className="form-label">Cabang Bank</label>
                                <input type="text" id='modal-form-1' className='form-control mb-2' name='bank_branch' defaultValue={bank.bank_branch} required />
                            </form>
                        </div>
                        {/* <!-- END: Modal Body --> */}
                        {/* <!-- BEGIN: Modal Footer --> */}
                        <div className="modal-footer">
                            <button id="closeModalEditBank" type="button" data-dismiss="modal" className="btn btn-outline-secondary w-20 mr-1">Cancel</button>
                            <button id="editBank" type="button" className="btn btn-primary w-20">Edit</button>
                        </div>
                        {/* <!-- END: Modal Footer --> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AppsSetting