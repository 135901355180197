import { useState, useEffect, useContext } from "react";
import { AppStateContext } from "../context/AppStateContext";
import {
  socket,
  loadScript,
  emit,
  validation,
  getFormData,
  showNotify,
} from "../context/socket";
import { Avatar, fullname, username } from "react-lorem-ipsum";
import { ConvertIsoDate } from "../context/common_function";
import AHrefJavascript from "./AHrefJavascript";
import { Link } from "react-router-dom";
import $ from "jquery";
import moment from "moment";
const UserProfile = ({ setPath, location }) => {
  const [user, setUser] = useState({});
  useEffect(() => {
    const getUser = async (id) => {
      emit("get_detail_user", { idUser: id }, (response) => {
        if (response.response < 200 || response.response >= 300)
          return showNotify(response);
        setUser(response.data[0]);
      });
    };
    setPath({ title: "User", path: "/users" });
    loadScript();
    getUser(location.pathname.split("/").reverse()[0]);

    $(() => {
      $("#sendNotify").on("click", () => {
        sendNotification();
      });
      $("#bannedUser").on("click", () => {
        bannedUser();
      });
    });
    return () => {
      console.log("Remove listener");
    };
  }, []);
  const sendNotification = async () => {
    console.log("Kirim Notip");
    var required = $("#notify input,textarea,select").filter("[required]");
    required = $.map(required, function (value, index) {
      return value.name;
    });
    var form2 = getFormData($("#notify"));
    if (!validation(form2, required)) return;
    console.log(form2);
    emit("send_notification", form2, (response) => {
      showNotify(response);
      if (response.response < 200 || response.response >= 300) return;
      $("#closeModal").trigger("click");
    });
  };
  const bannedUser = async () => {
    console.log("Banned User");
    var required = $("#banned input,textarea,select").filter("[required]");
    required = $.map(required, function (value, index) {
      return value.name;
    });
    var form2 = getFormData($("#banned"));
    if (!validation(form2, required)) return;
    console.log(form2);
    emit("banned_user", form2, (response) => {
      showNotify(response);
      if (response.response < 200 || response.response >= 300) return;
      $("#closeModalBanned").trigger("click");
    });
  };
  const getLastOnline = (date) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const lastLoginDate = new Date(date);
    const todaysDate = new Date(); // Current date
    const diffDays = Math.abs((lastLoginDate - todaysDate) / oneDay);
    if (diffDays < 1) {
      if (diffDays * 24 < 1)
        return Math.round(diffDays * 24 * 60) + " minutes ago";
      return Math.round(diffDays * 24) + " hours ago";
    }
    return Math.round(diffDays) + " days ago";
  };
  const userEmail = (data) => {
    if (!data._id) return "";
    if (data.status != "active" && data.email) {
      var string = data.email.split("@");
      console.log(string);
      var name = string[0].substring(0, string[0].length - 4) + "****@";
      return name + string[1];
    }
    return data.email;
  };
  return (
    <div className="intro-y">
      {/* <!-- END: Top Bar --> */}
      <div className="intro-y flex items-center mt-8">
        <h2 className="text-lg font-medium mr-auto">Profile Layout</h2>
      </div>
      <div className="grid grid-cols-12 gap-2 xl:gap-6 mt-5">
        {/* <!-- BEGIN: Profile Menu --> */}
        <div className="col-span-12 lg:col-span-5 xl:col-span-4 lg:block flex-col-reverse">
          <div className="intro-y box mt-5 lg:mt-0">
            <div className="relative flex items-center p-5">
              <div className="w-12 image-fit">
                {/* <Avatar gender="male" className="rounded-full" /> */}
              </div>
              <div className="ml-4 mr-auto">
                {/* <div className="font-medium text-base">Johnny Depp</div>
                                    <div className="text-gray-600">Frontend Engineer</div> */}
              </div>
              <div className="dropdown">
                <Link
                  to={"/users/edit/" + user._id ?? ""}
                  aria-expanded="false"
                  className="dropdown-toggle w-5 h-5 block"
                >
                  Edit{" "}
                </Link>
              </div>
            </div>
            <div className="block w-40 mx-auto pb-5">
              {/* <Avatar  gender="male" className="rounded-full max-h-40" /> */}
              <img
                style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
                className="tooltip w-full rounded-full"
                src={
                  user.status == "active"
                    ? user.userPhoto ??
                      "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                    : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                }
                title="Foto"
              />
            </div>
            <div>
              <div className="text-center text-lg font-semibold">
                {user.status == "active" ? user.name : "Deleted user"}
              </div>
              <div className="text-center text-sm text-gray-400">
                Last Online {getLastOnline(user.lastLogin)}
              </div>
              <br></br>
              <table className="table table-auto w-full">
                <tbody>
                  <tr>
                    <td>Email</td>
                    <td className="w-1">:</td>
                    <td className="text-left">{userEmail(user)}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td className="w-1">:</td>
                    <td className="text-left">{user.phone}</td>
                  </tr>
                  <tr>
                    <td>Birth Date</td>
                    <td className="w-1">:</td>
                    <td className="text-left">
                      {moment(new Date(user.birth_date)).format("LL")}
                    </td>
                  </tr>
                  <tr>
                    <td>Join Date</td>
                    <td className="w-1">:</td>
                    <td className="text-left">
                      {moment(new Date(user.createdAt)).format("LL")}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="flex p-5 justify-between items-center ">
                {/* <button className="btn btn-danger shadow-md mr-2">Ban User</button> */}
                <AHrefJavascript
                  href="#!"
                  data-toggle="modal"
                  className="btn btn-danger shadow-md mr-2"
                  data-target="#banned-modal"
                >
                  Ban User
                </AHrefJavascript>
                <AHrefJavascript
                  href="#!"
                  data-toggle="modal"
                  data-target="#notify-modal"
                  style={{ color: "#1B389F" }}
                >
                  Send Notification
                </AHrefJavascript>
              </div>
            </div>
          </div>
          <div className="intro-y box p-5 bg-theme-1 mt-5">
            <div className="flex items-center">
              <div className="font-medium text-lg">Total Transaction</div>
              <div className="text-xs bg-white dark:bg-theme-1 dark:text-white text-gray-800 px-1 rounded-md ml-auto"></div>
            </div>
            <div className="mt-4 text-3xl font-medium">
              Rp. {(user.totalTrans ?? 0).toLocaleString()}
            </div>
          </div>
        </div>
        {/* <!-- END: Profile Menu --> */}
        <div className="col-span-12 lg:col-span-5 xl:col-span-4">
          <div
            className="intro-y box w-full flex flex-col"
            style={{ maxHeight: "60vh" }}
          >
            <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5">
              <h2 className="font-medium text-base mr-auto">
                Consultation History
              </h2>
              <h2 className="font-medium text-base">{user.transCount}</h2>
            </div>
            <div className="p-3 overflow-y-scroll text-sm h-full">
              {user.list_trans?.map((e, i) => {
                return (
                  <div
                    key={e.createdAt}
                    className={
                      "relative flex items-center " + (i != 0 ? "mt-5" : "")
                    }
                  >
                    <div className="w-12 h-12 flex-none image-fit">
                      <img
                        style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
                        className="tooltip w-full rounded-full"
                        src={
                          e.lawyer.fullPhoto ||
                          "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        }
                        title="Foto"
                      />
                    </div>
                    <div className="ml-4 flex flex-col mr-auto">
                      <Link
                        to={"/lawyers/view/" + e.lawyer._id}
                        className="font-medium"
                      >
                        {e.lawyer.name}
                      </Link>
                      <Link
                        to={"/lawyers/view/" + e._id}
                        className="text-gray-600"
                        style={{ fontSize: "10px" }}
                      >
                        {e.invoice}
                      </Link>
                    </div>
                    <div className="font-medium text-gray-700 dark:text-gray-500">
                      Rp. {(e.price ?? 0).toLocaleString()}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-span-12 lg:col-span-5 xl:col-span-4">
          <div
            className="intro-y box w-full flex flex-col "
            style={{ maxHeight: "60vh" }}
          >
            <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5">
              <h2 className="font-medium text-base mr-auto">Report</h2>
            </div>
            <div className="p-3 overflow-y-scroll ">
              {user.list_reporter?.map((e, i) => {
                return (
                  <div
                    key={e.createdAt}
                    className={
                      "relative flex text-sm  items-center " +
                      (i != 0 ? "mt-5" : "")
                    }
                  >
                    <div className="w-12 h-12 flex-none image-fit">
                      <img
                        style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
                        className="tooltip w-full rounded-full"
                        src={
                          e.userReported.fullPhoto ||
                          "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        }
                        title="Foto"
                      />
                    </div>
                    <div className="ml-4 mr-auto">
                      <Link
                        to={"/lawyers/view/" + e.userReported._id}
                        className="font-medium"
                      >
                        {e.userReported.name}
                      </Link>
                      <div className="text-gray-600 mr-5 sm:mr-5">
                        {e.description}
                      </div>
                    </div>
                    <Link
                      to={"/pengaduan/view/" + e._id}
                      className="font-medium cursor-pointer text-gray-700 dark:text-gray-500"
                    >
                      <span
                        className={
                          "material-icons " +
                          (e.status == "active"
                            ? "text-red-500"
                            : "text-green-400")
                        }
                      >
                        {e.status != "active" ? "task_alt" : "pending"}
                      </span>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div id="notify-modal" className="modal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <!-- BEGIN: Modal Header --> */}
            <div className="modal-header">
              <h2 className="font-medium text-base mr-auto">
                Send Push Notification
              </h2>
            </div>
            {/* <!-- END: Modal Header --> */}
            {/* <!-- BEGIN: Modal Body --> */}
            <div className={`px-5 pt-5`}>
              <div className={`text-gray-400`}>
                This user will recieve notification on their account.
              </div>
              <br></br>
              <form id="notify">
                <input
                  type="hidden"
                  name="idUser"
                  required
                  value={user._id ?? ""}
                />
                <label htmlFor="modal-form-1" className="form-label">
                  Title
                </label>
                <input
                  id="modal-form-1"
                  type="text"
                  // value={'Testing kirim notify'}
                  name="title"
                  className="form-control mb-4"
                  required
                  minLength="10"
                  maxLength="40"
                  placeholder="Max 40 Character"
                />
                <label htmlFor="modal-form-2" className="form-label">
                  Description
                </label>
                <textarea
                  id="modal-form-2"
                  className="form-control"
                  // value={'Testing kirim notify 123123123123'}
                  name="description"
                  placeholder="Max 120 Character"
                  minLength="10"
                  maxLength={120}
                  required
                ></textarea>
              </form>
            </div>
            {/* <!-- END: Modal Body --> */}
            {/* <!-- BEGIN: Modal Footer --> */}
            <div className="modal-footer">
              <button
                id="closeModal"
                type="button"
                data-dismiss="modal"
                className="btn btn-outline-secondary w-20 mr-1"
              >
                Cancel
              </button>
              <button
                id="sendNotify"
                type="button"
                className="btn btn-primary w-20"
              >
                Send
              </button>
            </div>
            {/* <!-- END: Modal Footer --> */}
          </div>
        </div>
      </div>
      <div id="banned-modal" className="modal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            {/* <!-- BEGIN: Modal Header --> */}
            <div className="modal-header">
              <h2 className="font-medium text-base mr-auto">
                Send Push Notification
              </h2>
            </div>
            {/* <!-- END: Modal Header --> */}
            {/* <!-- BEGIN: Modal Body --> */}
            <div className={`px-5 pt-5`}>
              <div className={`text-gray-400`}>
                This user will recieve notification on their account.
              </div>
              <br></br>
              <form id="banned">
                <input
                  type="hidden"
                  name="idUser"
                  required
                  value={user._id ?? ""}
                />
                <label htmlFor="modal-form-1" className="form-label">
                  Until Date
                </label>
                <input
                  id="modal-form-1"
                  type="date"
                  name="ban_date"
                  required
                  defaultValue={ConvertIsoDate(Date())}
                  className="datepicker form-control col-span-4"
                  data-single-mode="true"
                  data-format="YYYY-MM-DD"
                  placeholder="Select Date"
                />
                <label htmlFor="modal-form-2" className="form-label">
                  Reason Banned
                </label>
                <textarea
                  id="modal-form-2"
                  className="form-control"
                  // value={'Testing kirim notify 123123123123'}
                  name="description"
                  placeholder="Max 120 Character"
                  minLength="10"
                  maxLength={120}
                  required
                ></textarea>
              </form>
            </div>
            {/* <!-- END: Modal Body --> */}
            {/* <!-- BEGIN: Modal Footer --> */}
            <div className="modal-footer">
              <button
                id="closeModalBanned"
                type="button"
                data-dismiss="modal"
                className="btn btn-outline-secondary w-20 mr-1"
              >
                Cancel
              </button>
              <button
                id="bannedUser"
                type="button"
                className="btn btn-primary w-20"
              >
                Banned
              </button>
            </div>
            {/* <!-- END: Modal Footer --> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
