import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  socket,
  loadScript,
  emit,
  debounce,
  showNotify,
} from "../context/socket";
import Pagination from "./Pagination";
import moment from "moment";
const User = ({ setPath }) => {
  const [users, setUsers] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState({
    number: 0,
    time: new Date().getTime(),
  });
  const [docsCounts, setDocsCounts] = useState(0);
  const [text, setText] = useState("");
  const [filter, setFilter] = useState("createdAt");
  const [type, setType] = useState(-1);

  const getUser = async (page) => {
    var reqData = {
      text: text,
      page: page.number,
      size: pageSize,
      filter: filter,
      type: parseInt(type.toString()),
    };
    emit("get_users", reqData, (response) => {
      if (response.response < 200 || response.response >= 300)
        return showNotify(response);
      setUsers(response.data.docs);
      setDocsCounts(response.data.counts);
    });
  };
  useEffect(() => {
    setCurrentPage({ number: 1 });
  }, [pageSize, text, filter, type]);
  useEffect(() => {
    if (currentPage.number != 0) getUser(currentPage);
  }, [currentPage]);
  useEffect(() => {
    setPath({ title: "User", path: "/users" });
    loadScript();
  }, []);
  const searchUser = (data) => {
    console.log(data.target.value);
    debounce(() => setText(data.target.value), 2000);
  };
  const newTo = (data) => {
    return { pathname: "/users/profile/" + data._id };
  };
  const userEmail = (data) => {
    if (data.status != "active" && data.email) {
      var string = data.email.split("@");
      console.log(string);
      var name = string[0].substring(0, string[0].length - 4) + "****@";
      return name + string[1];
    }
    return data.email;
  };
  return (
    <div className="intro-y">
      {/* <!-- END: Top Bar --> */}
      <h2 className="intro-y text-lg font-medium mt-10">Daftar User</h2>
      <div className="grid grid-cols-12 gap-6 mt-5">
        <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          {/* <button className="btn btn-primary shadow-md mr-2">Tambah User</button> */}
          {/* <div className="dropdown">
                        <button className="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300" aria-expanded="false">
                            <span className="w-5 h-5 flex items-center justify-center"> <i className="w-4 h-4" data-feather="plus"></i> </span>
                        </button>
                        <div className="dropdown-menu w-40">
                            <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="printer" className="w-4 h-4 mr-2"></i> Print </a>
                                <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="file-text" className="w-4 h-4 mr-2"></i> Export to Excel </a>
                                <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="file-text" className="w-4 h-4 mr-2"></i> Export to PDF </a>
                            </div>
                        </div>
                    </div> */}
          <div className="hidden md:block mx-auto text-gray-600">
            Showing {currentPage.number * pageSize - pageSize + 1} to{" "}
            {currentPage.number * pageSize} of {docsCounts} entries
          </div>
          <div className="w-full sm:w-auto flex mt-4 sm:mt-0">
            <div
              onChange={(e) => setFilter(e.target.value)}
              className="dropdown mr-2 w-56 relative text-gray-700 dark:text-gray-300"
            >
              <select
                className="tom-select sm:mr-2"
                defaultValue={filter}
                placeholder="Urut Berdasarkan"
              >
                <option value={"lastLogin"}>Terakhir Login</option>
                <option value={"name"}>Nama</option>
                <option value={"createdAt"}>Tanggal Registrasi</option>
                <option value={"totalTrans"}>Total Transaksi</option>
              </select>
            </div>
            <div
              onChange={(e) => setType(e.target.value)}
              className="dropdown mr-2 w-56 relative text-gray-700 dark:text-gray-300"
            >
              <select
                className="tom-select sm:mr-2"
                defaultValue={type}
                placeholder="Urutan"
              >
                <option value={1}>A to Z</option>
                <option value={-1}>Z to A</option>
              </select>
            </div>
            <div className="w-56 relative text-gray-700 dark:text-gray-300">
              <input
                type="text"
                onChange={searchUser}
                className="form-control w-56 box pr-10 placeholder-theme-13"
                placeholder="Cari Nama User/ Email"
              />
              <i
                className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
                data-feather="search"
              ></i>
            </div>
          </div>
        </div>
        {/* <!-- BEGIN: Data List --> */}
        <div className="intro-y col-span-12 overflow-auto">
          <table className="table table-report -mt-2">
            <thead>
              <tr>
                <th className="whitespace-nowrap">No.</th>
                <th className="whitespace-nowrap" colSpan="2">
                  Nama User
                </th>
                <th className="text-center whitespace-nowrap">
                  Jumlah Konsultasi
                </th>
                <th className="text-center whitespace-nowrap">
                  Tanggal Registrasi
                </th>
                <th className="text-center whitespace-nowrap">
                  Total Transaction
                </th>
                <th className="text-center whitespace-nowrap">
                  Terakhir Online
                </th>
                <th className="text-center whitespace-nowrap">ACTIONS</th>
              </tr>
            </thead>
            <tbody>
              {users.map((d, i) => {
                i *= 1;
                return (
                  <tr key={i} className="intro-x">
                    <td className="w-1">
                      <div className="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                        {++i}
                      </div>
                    </td>
                    <td className="w-1">
                      <div className="flex">
                        <div className="w-10 h-10 image-fit zoom-in">
                          <img
                            className="tooltip rounded-full"
                            src={
                              d.status == "active"
                                ? d.userPhoto ??
                                  "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                            }
                            title="Foto"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      {/* <a href="" className="font-medium whitespace-nowrap">Dell XPS 13</a>  */}
                      <div>
                        {d.status == "active" ? d.name : "Deleted user"}
                      </div>
                      <div
                        className="text-gray-600 text-xs whitespace-wrap mt-0.5"
                        key={d.name}
                      >
                        {userEmail(d)}
                      </div>
                    </td>
                    <td className="text-center">{d.transCount}</td>
                    <td className="text-center">
                      {moment(new Date(d.createdAt)).format("L")}
                    </td>
                    <td className="text-center" key={d}>
                      Rp. {d.totalTrans.toLocaleString()}
                    </td>
                    <td className="text-center">
                      {moment(new Date(d.lastLogin)).format("L")}
                    </td>
                    <td className="table-report__action ">
                      <div className="flex justify-center items-center">
                        <Link to={newTo(d)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-check-square w-4 h-4 mr-1"
                          >
                            <polyline points="9 11 12 14 22 4"></polyline>
                            <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
                          </svg>{" "}
                          View
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* <!-- BEGIN: Pagination --> */}
        <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
          <Pagination
            className="pagination-bar"
            currentPage={currentPage.number}
            totalCount={docsCounts}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage({ number: page })}
          />
          <div className="w-full"></div>
          <select
            defaultValue={10}
            onChange={(e) => setPageSize(parseInt(e.target.value))}
            style={{ width: "100px" }}
            className="form-select box mt-3 sm:mt-0"
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={35}>35</option>
            <option value={50}>50</option>
          </select>
        </div>
        {/* <!-- END: Pagination --> */}
      </div>
    </div>
  );
};

export default User;
